<template>
  <div>
    <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : true">
      <v-row
        class="service-main-wrapper pt--80 mt--2 mr--2 mb--2 ml--2"
        v-for="(skillset, i) in skills"
        :key="i"
      >
        <v-col
          cols="12"
          lg="3"
          md="3"
          class="text-center pb--20 skill_anchor d-flex align-center"
          v-for="(skill, j) in skillset"
          :key="j"
        >
          <v-col cols="6">
            <a :href="skill.link" target="_blank">
              <v-img :lazy-src="require('../assets/images/spinner.svg')" contain max-height="70" max-width="70" :src="skill.logo" class="skill_img" />
            </a>
          </v-col>
          <v-col cols="6" class="text-left">
            <v-col cols="12">
              <h5>{{ skill.name }}</h5>
            </v-col>
            <v-col cols="12">
              <span style="font-size: 1.2em">{{ skill.experience }}</span>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row
        class="service-main-wrapper pt--40 mt--10 mr--10 mb--10 ml--10"
        v-for="(skill, i) in skillsMobile"
        :key="i"
      >
        <v-col
          cols="12"
          sm="12"
          class="text-center skill_anchor d-flex align-center"
        >
          <v-col cols="6">
            <a :href="skill.link" target="_blank">
              <v-img :lazy-src="require('../assets/images/spinner.svg')" contain max-height="70" max-width="70" :src="skill.logo" class="skill_img" />
            </a>
          </v-col>
          <v-col cols="6" class="text-left">
            <v-col cols="12">
              <h5>{{ skill.name }}</h5>
            </v-col>
            <v-col cols="12">
              <span style="font-size: 1.2em">{{ skill.experience }}</span>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      skillsMobile: [
          {
            name: "HTML",
            logo: require("../assets/images/portfolio-skills/html.svg"),
            link: "https://html.spec.whatwg.org/",
            experience: "2+ yrs",
          },
          {
            name: "CSS",
            logo: require("../assets/images/portfolio-skills/css.svg"),
            link: "https://www.w3.org/TR/CSS/#css",
            experience: "2+ yrs",
          },
          {
            name: "JavaScript",
            logo: require("../assets/images/portfolio-skills/javascript.svg"),
            link: "https://www.ecma-international.org/publications-and-standards/standards/ecma-262/",
            experience: "4+ yrs",
          },
          {
            name: "PHP",
            logo: require("../assets/images/skills/php-logo.svg"),
            link: "https://www.php.net/",
            experience: "4+ yrs",
          },
          {
            name: "Laravel",
            logo: require("../assets/images/portfolio-skills/laravel.svg"),
            link: "https://laravel.com/",
            experience: "4+ yrs",
          },
          {
            name: "Vue JS",
            logo: require("../assets/images/skills/vuejs-logo.svg"),
            link: "https://laravel.com/",
            experience: "2.5 yrs",
          },
          {
            name: "MySQL",
            logo: require("../assets/images/portfolio-skills/mysql.svg"),
            link: "https://www.mysql.com/",
            experience: "4+ yrs",
          },
          {
            name: "Livewire",
            logo: require("../assets/images/portfolio-skills/livewire.svg"),
            link: "https://laravel-livewire.com/",
            experience: "2+ yrs",
          },
          {
            name: "jQuery",
            logo: require("../assets/images/skills/jquery-logo.svg"),
            link: "https://jquery.com/",
            experience: "4+ yr",
          },
          {
            name: "Alpine JS",
            logo: require("../assets/images/portfolio-skills/alpine.svg"),
            link: "https://alpinejs.dev/",
            experience: "2+ yr",
          },
          {
            name: "Tailwind CSS",
            logo: require("../assets/images/portfolio-skills/tailwind.svg"),
            link: "https://tailwindcss.com/",
            experience: "3 months",
          },
          {
            name: "GIT",
            logo: require("../assets/images/skills/github-logo.svg"),
            link: "https://github.com/",
            experience: "5+ yrs",
          },
          {
            name: "Node JS",
            logo: require("../assets/images/portfolio-skills/nodejs.svg"),
            link: "https://nodejs.org/",
            experience: "8+ months",
          },
          {
            name: "React JS",
            logo: require("../assets/images/portfolio-skills/react.svg"),
            link: "https://reactjs.org/",
            experience: "4 months",
          },
          {
            name: "REST APIs",
            logo: require("../assets/images/portfolio-skills/rest-api.svg"),
            link: "https://en.wikipedia.org/wiki/Representational_state_transfer",
            experience: "4+ years",
          },
          // {
          //   name: "Open Source Contributions",
          //   logo: require("../assets/images/portfolio-skills/open-source.svg"),
          //   link: "https://github.com/",
          //   experience: "",
          // }
      ],
      skills: {
        first_set: [
          {
            name: "HTML",
            logo: require("../assets/images/portfolio-skills/html.svg"),
            link: "https://html.spec.whatwg.org/",
            experience: "2+ yrs",
          },
          {
            name: "CSS",
            logo: require("../assets/images/portfolio-skills/css.svg"),
            link: "https://www.w3.org/TR/CSS/#css",
            experience: "2+ yrs",
          },
          {
            name: "JavaScript",
            logo: require("../assets/images/portfolio-skills/javascript.svg"),
            link: "https://www.ecma-international.org/publications-and-standards/standards/ecma-262/",
            experience: "4+ yrs",
          },
          {
            name: "PHP",
            logo: require("../assets/images/skills/php-logo.svg"),
            link: "https://www.php.net/",
            experience: "4+ yrs",
          },
        ],
        second_set: [
          {
            name: "Laravel",
            logo: require("../assets/images/portfolio-skills/laravel.svg"),
            link: "https://laravel.com/",
            experience: "4+ yrs",
          },
          {
            name: "Vue JS",
            logo: require("../assets/images/skills/vuejs-logo.svg"),
            link: "https://laravel.com/",
            experience: "2.5 yrs",
          },
          {
            name: "MySQL",
            logo: require("../assets/images/portfolio-skills/mysql.svg"),
            link: "https://www.mysql.com/",
            experience: "4+ yrs",
          },
          {
            name: "Livewire",
            logo: require("../assets/images/portfolio-skills/livewire.svg"),
            link: "https://laravel-livewire.com/",
            experience: "2+ yrs",
          },
        ],
        third_set: [
          {
            name: "jQuery",
            logo: require("../assets/images/skills/jquery-logo.svg"),
            link: "https://jquery.com/",
            experience: "4+ yr",
          },
          {
            name: "Alpine JS",
            logo: require("../assets/images/portfolio-skills/alpine.svg"),
            link: "https://alpinejs.dev/",
            experience: "2+ yr",
          },
          {
            name: "Tailwind CSS",
            logo: require("../assets/images/portfolio-skills/tailwind.svg"),
            link: "https://tailwindcss.com/",
            experience: "3 months",
          },
          {
            name: "GIT",
            logo: require("../assets/images/skills/github-logo.svg"),
            link: "https://github.com/",
            experience: "5+ yrs",
          },
        ],
        fourth_set: [
          {
            name: "Node JS",
            logo: require("../assets/images/portfolio-skills/nodejs.svg"),
            link: "https://nodejs.org/",
            experience: "8+ months",
          },
          {
            name: "React JS",
            logo: require("../assets/images/portfolio-skills/react.svg"),
            link: "https://reactjs.org/",
            experience: "4 months",
          },
          {
            name: "REST APIs",
            logo: require("../assets/images/portfolio-skills/rest-api.svg"),
            link: "https://en.wikipedia.org/wiki/Representational_state_transfer",
            experience: "4+ years",
          },
          // {
          //   name: "Open Source Contributions",
          //   logo: require("../assets/images/portfolio-skills/open-source.svg"),
          //   link: "https://github.com/",
          //   experience: "",
          // },
        ],
      }
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
