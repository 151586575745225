<template>
  <div id="parent">
  <Home v-if="currentRouteName == 'Home'"/>
  <Portfolio v-if="currentRouteName == 'Portfolio'" />
  </div>
</template>
<script>
import Home from "../components/Portfolio/Home";
import Portfolio from "../components/Portfolio/Portfolio";

export default {
  components: {
    Home,
    Portfolio
  },
  data() {
    return {};
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
}
};
</script>
