<template>
  <v-row class="service-main-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="service service__style--2 text-left bg-gray">
        <router-link to="/service-details">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: "calendar",
          title: "Planning",
          desc: `Requirement analysis to define steps to be followed for successful product implementation.`,
        },
        {
          icon: "sliders",
          title: "Designing",
          desc: `Determining best architectural & design approach to establish efficient & solid foundation.`,
        },
        {
          icon: "settings",
          title: "Development",
          desc: `Time to code! Programming clean, scalable and well-structured codebase.`,
        },
        {
          icon: "check-circle",
          title: "Testing",
          desc: `Full throttle testing & bug-fixing, ensuring highest standards of product delivery`,
        },
        {
          icon: "upload-cloud",//truck
          title: "Deployment",
          desc: `Product deployment, shipping robust, reliable and seamless user experience`,
        },
        {
          icon: "life-buoy",
          title: "Maintenance",
          desc: `Maintenance & support catering high quality and consistent product available at clockwork`,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
