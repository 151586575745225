<template>
  <v-container>
    <v-row align="center">
      <v-col lg="12" md="12" cols="12">
        <div class="about-inner inner timeline_exp">
          <v-timeline
            :dense="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false
            "
          >
            <v-timeline-item
              v-for="(item, i) in experiences"
              :key="i"
              :fill-dot="false"
              :small="i == 2 ? true : false"
            >
              <v-card class="mx-auto" outlined elevation="5" shaped>
                <div
                  class="text-overline pt-5 pl-5 d-flex justify-space-between"
                >
                  <span>{{ item.time }}</span>
                  <v-avatar class="mr-5" color="#e75434" size="40" tile>
                    <v-img :lazy-src="require('../assets/images/spinner.svg')" :src="item.src"></v-img>
                  </v-avatar>
                </div>
                <v-card-title class="theme-gradient">{{
                  item.role
                }}</v-card-title>
                <v-card-subtitle
                  >{{ item.employer }} - {{ item.duration }}</v-card-subtitle
                >
                <v-card-text class="pl-5 pr-5"  v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : true">
                  {{ item.description }}
                </v-card-text>
                <!-- <v-card-text class="pl-5">
                  {{ item.highlight }}
                </v-card-text>
                <v-list-item class="three-line">
                  <v-list-item-content>
                    <v-list-item-subtitle v-for="(bullet, j) in item.description_bullets" :key="j">
                      <v-card-subtitle v-html="bullet"></v-card-subtitle>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      experiences: [
        {
          role: "Full Stack Developer",
          employer: "Digitalchamps.nl",
          src: require("../assets/images/employers/digitalchamps.png"),
          duration: "2 yrs",
          time: "2021 - Present",
          description:
            "This is where I advanced my Career through gained knowledge over previous experience, I Engineered modern web applications using various cutting-edge technologies and I was reponsible for managing applications for over 6 different clientele.",
          description_bullets: [
            "Developed full-stack web applications which processed, analyzed, and rendered data visually.",
            "Liaised with back end developers, front end developers, quality assurance testers, and CTO as needed.",
            "Managed time-sensitive updates, including content changes and database upgrades.",
          ],
          highlight:
            "Developed a launch website for selling NFTs, sold 100% NFTs (8888) within 3 hours of launch with zero percent downtime, generating $250k sales revenue.",
        },
        {
          role: "Software Developer",
          employer: "Trimantra Software Solution LLC",
          src: require("../assets/images/employers/trimantra.png"),
          duration: "2.6 yrs",
          time: "2018 - 2021",
          description:
            "This is where I started my career, I was assigned to Laravel + VueJs project from day 1, I went through steep learning curve and Understood all things web.",
          description_bullets: [
            "Developed full-stack web applications which processed, analyzed, and rendered data visually.",
            "Liaised with back end developers, front end developers, quality assurance testers, and CTO as needed.",
            "Managed time-sensitive updates, including content changes and database upgrades.",
          ],
          highlight:
            "Developed a launch website for selling NFTs, sold 100% NFTs (8888) within 3 hours of launch with zero percent downtime, generating $250k sales revenue.",
        },
        {
          role: "Bachelor of Technology",
          employer: "Computer Science Engineering",
          src: require("../assets/images/employers/rtu.png"),
          duration: "4 yrs",
          time: "2014 - 2018",
          description:
            'Here is where it all started, I wrote my first lines of code, "Hello World" using JavaScript, and Followed my passion of learning to code.',
          description_bullets: [
            "Developed full-stack web applications which processed, analyzed, and rendered data visually.",
            "Liaised with back end developers, front end developers, quality assurance testers, and CTO as needed.",
            "Managed time-sensitive updates, including content changes and database upgrades.",
          ],
        },
      ],
    };
  },
};
</script>
