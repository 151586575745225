<template>
    <div>
      <v-expansion-panels
        accordion
        flat
        v-model="panelModel"
        multiple
        class="about-expension-panels"
      >
      <v-expansion-panel v-for="(item, i) in accordionContent" :key="i">
        <v-expansion-panel-header hide-actions>{{
          item.title
        }}</v-expansion-panel-header>
        <v-expansion-panel-content v-html="item.content"></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </div>
  </template>

  <script>
    export default {
      props: ['panel'],
      data() {
        return {
          panelModel: this.panel,
          accordionContent: [
            {
              title: "I did full scale Gsuite integration (including all major google APIs) featuring google oAuth for application's login functionality using Laravel.",
              content: "With team of a developer, a designer and a manger, after extensive requirement analysis and in-depth understanding of business requirements, We boostrapped the application with Laravel. As a developer, I did full scale Gsuite integration (including all major google APIs) featuring google oAuth for application's login functionality."
            },
            {
              title: "The system was splitted in two different intranet applications",
              content: `The system was splitted in two different intranet applications, Calculation tool (for precalculation estimation of projects to be done) and Planboard (for Internal business processes). Planboard was further divided into backend for administrator, managerial roles and tablet application for field employees. I was responsible for Planboard side of the application.`,
            }
          ],
        };
      },
    };
  </script>