<template>
  <v-row>
    <v-col cols="12">
      <div class="slick-space-gutter--15 slickdot--20">
        <VueSlickCarousel
          v-bind="settings"
          class="rn-slick-activation slick-dotted rn-slick-dot"
        >
          <!-- Start Single Portfolio  -->
          <div
            class="single_im_portfolio"
            v-for="(item, i) in portfolioContent"
            :key="i"
          >
          <router-link
                :to="'/portfolio/'+item.client">
            <div class="im_portfolio">
              <a href="portfolio-details.html">
                <img class="w-100" :src="item.src" alt="Portfolio Images"/>
              </a>
              <div class="content">
                <div class="inner">
                  <div class="portfolio_heading white--text">
                    <div class="category_list">
                      {{ item.category }}
                    </div>
                    <h4 class="heading-title">
                      {{ item.title }}
                    </h4>
                  </div>
                  <div class="portfolio_hover">
                    <p>View Project</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          </div>
          <!-- End Single Portfolio  -->
        </VueSlickCarousel>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  props: ['client'],
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      portfolioContent: [
        {
          src: require("../assets/images/portfolio-screens/snowpunkz.jpg"),
          category: "Cardano NFT Project",
          title: "SnowPunkz",
          client: "snowpunkz"
        },
        {
          src: require("../assets/images/portfolio-screens/pocketguide.jpg"),
          category: "Audio tour Platform",
          title: "Pocketguide",
          client: "pocketguide"
        },
        {
          src: require("../assets/images/portfolio-screens/mesa.jpg"),
          category: "Internal Operation Application",
          title: "Mesa",
          client: "mesa-coatings"
        },
        {
          src: require("../assets/images/portfolio-screens/msgold.jpg"),
          category: "Product marketing application",
          title: "MSGold",
          client: "msgold"
        },
        {
          src: require("../assets/images/portfolio-screens/motorpark.png"),
          category: "Ecommerce for Motorcycles",
          title: "Motorpark",
          client: "motorpark"
        },
        // {
        //   src: require("../assets/images/portfolio-screens/kroeten.jpg"),
        //   category: "External Operation Application",
        //   title: "Kroetenwind",
        //   client: "kroetenwind"
        // },
        // {
        //   src: require("../assets/images/portfolio/portfolio-6.jpg"),
        //   category: "Medical",
        //   title: "Medical Application",
        //   description:
        //     "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
        // },
      ],
      // for all works
      settings: {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1263,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
      },
    };
  },
};
</script>
