<template>
    <div id="parent">
      <!-- Start Header Area -->
      <Header />
      <!-- End Header Area -->

      <!-- Start Breadcrump Area  -->
      <div
        class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
        data-black-overlay="6"
      >
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="breadcrumb-inner pt--100">
                <h2 class="heading-title">
                  {{ clientobj[0]["name"] }} Portfolio
                </h2>
                <ul class="page-list">
                  <li
                    class="rn-breadcrumb-item"
                    v-for="(item, i) in breadcrumbs"
                    :key="i"
                  >
                    <router-link :to="item.to">{{ item.text }}</router-link>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->

      <!-- End Breadcrump Area  -->

      <!-- Start Key Highlights Area  -->
      <div class="rn-service-details ptb--80 bg_color--1">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="service-details-inner">
                <div class="inner">
                  <!-- Start Single Content  -->
                  <v-row
                    class="
                      sercice-details-content
                      align-center
                      row--35
                      justify-center
                    "
                  >
                    <v-col lg="10" md="10" cols="10">
                      <v-card class="elevation-10" outlined>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-card-title> Key Highlight </v-card-title>
                            <v-card-subtitle
                              v-html="clientobj[0]['highlight']"
                            ></v-card-subtitle>
                          </v-list-item-content>
                          <div
                            style="color: #fe4766; transform: scale(1.3)"
                            v-html="iconSvg('award')"
                          ></div>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Key Highlights Area  -->

      <!-- Start Portfolio Details Area  -->
      <div class="rn-service-details ptb--120 bg_color--5">
        <v-container>
          <v-row class="align-center mt--10 mr--10 mb--10 ml--10">
            <v-col lg="6" md="6" sm="8" offset-sm="2" offset-md="0" offset-lg="0">
              <v-img contain
                :lazy-src="require('../../assets/images/spinner.svg')"
                max-height="600"
                max-width="650"
                :src="clientobj[0]['featured_image']"
              ></v-img>
            </v-col>
            <v-col lg="6" md="6" sm="12" class="mt_md--30 mt_sm--30">
                <div class="section-title">
                  <span class="subtitle">Understanding the Requirement</span>
                </div>
                <h2 class="heading-title">
                  {{
                    clientobj[0]["name"] == "Mesa"
                      ? "Mesa Coatings"
                      : clientobj[0]["name"]
                  }}
                </h2>
                <a class="btn-transparent">About Client</a>
                <p class="description">
                  {{ clientobj[0]["about_client"] }}
                </p>
                <br />
                <a class="btn-transparent"> The Problem </a>
                <p class="description">
                  {{ clientobj[0]["problem"] }}
                </p>
                <div class="portfolio-details-btn mt--30" v-if="clientobj[0]['linkExists']">
                  <a
                    :href="clientobj[0]['linkExists']"
                    target="_blank"
                    class="btn-default btn-border">
                    Launch The Site
                  </a>
                </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- Start Counterup Area -->
      <div class="rn-counterup-area ptb--80 bg_color--1">
        <v-container>
          <v-row class="align-center row--35">
            <v-col cols="12">
              <div class="section-title text-center" >
                <span class="subtitle">Technologies used</span>
                <h2 class="heading-title">{{ clientobj[0].tech_stack }}</h2>
                <p class="description">
                  {{ clientobj[0]['tech_stack_desc'] }}
                </p>
              </div>
            </v-col>
          </v-row> <br></br>
          <v-row class="justify-center" v-if="clientobj[0].tech_stack == 'TALL Stack'">
            <v-col cols="2" class="text-center">
              <a href="https://tailwindcss.com/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/portfolio-skills/tailwind.svg')"
                  ></v-img>
              </a>
            </v-col>
            <v-col cols="2" class="text-center" >
              <a href="https://alpinejs.dev/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/portfolio-skills/alpine.svg')"
                  ></v-img>
              </a>
            </v-col>
            <v-col cols="2" class="text-center" >
              <a href="https://laravel.com/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/portfolio-skills/laravel.svg')"
                  ></v-img>
              </a>
            </v-col>
            <v-col cols="2" class="text-center" >
              <a href="https://laravel-livewire.com/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/portfolio-skills/livewire.svg')"
                  ></v-img>
              </a>
            </v-col>
          </v-row>
          <v-row class="justify-center" v-if="clientobj[0].tech_stack == 'LAMP Stack'">
            <v-col cols="2" class="text-center">
              <a href="https://www.linux.org/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/portfolio-skills/linux.png')"
                  ></v-img>
              </a>
            </v-col>
            <v-col cols="2" class="text-center" >
              <a href="https://www.apache.org/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/portfolio-skills/apache.svg')"
                  ></v-img>
              </a>
            </v-col>
            <v-col cols="2" class="text-center" >
              <a href="https://www.mysql.com/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/skills/mysql-logo.svg')"
                  ></v-img>
              </a>
            </v-col>
            <v-col cols="2" class="text-center" >
              <a href="https://www.php.net/" target="_blank">
                <v-img contain
                    :lazy-src="require('../../assets/images/spinner.svg')"
                    max-width="144"
                    max-height="70"
                    :src="require('../../assets/images/skills/php-logo.svg')"
                  ></v-img>
              </a>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Counterup Area -->


      <div
        class="rn-service-details ptb--120" v-if="clientobj[0].name == 'Mesa'"
      >
        <v-container>
          <!-- Start Single Content  -->
          <v-row class="sercice-details-content align-center row--35">
            <v-col lg="12" md="12" cols="12" order="2">
              <div class="details mt_md--30 mt_sm--30">
                <div class="section-title">
                  <span class="subtitle">Implementing the solution</span>
                  <h2 class="heading-title">
                    {{ clientobj[0]["action_title"] }}
                  </h2>
                  <a class="btn-transparent">The Action</a>
                  <div class="rn-accordion mt--20">
                    <Accordion :panel="[]" />
                  </div>
                  <br />
                  <p class="description">
                    A day for Mesa coatings consists of over 10 projects per day,
                    each project requires at least 4-5 field employees assigned,
                    <b
                      >I developed project and employee management, using which
                      manager employees could create a project and assign field
                      employees to it based on their availablities, and field
                      employees on the other hand could see their schedule of the
                      day in their tablet application dashboard. Process built for
                      the field employees was that they could work as per the
                      schedule and fill details of work into tablet application
                      for each project with authorised signature of clientele.</b
                    >
                  </p>
                  <p class="description">
                    Mesa had different sizes of projects, most of which were more
                    than one day of work, for that
                    <b
                      >I replicated google calendar recurring events functionality
                      into the application. Each project in the application
                      created corresponding event into Mesa administrator google
                      calendar, the employee assignment to the project was in sync
                      as well using google calendar invite & attendees.</b
                    >I developed more functionalities including payrolling and
                    real time tracking of field employees, interactive & data-rich
                    business reports and adding attachments to project events from
                    google drive.
                  </p>
                  <p class="description">
                    <b
                      >I was also responsible for building & managing REST API for
                      communication between two intranets and developing
                      Progressive Web App for the application.</b
                    >
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- End Single Content  -->
        </v-container>
      </div>
      <div class="rn-service-details ptb--120 bg_color--1" v-if="clientobj[0].name == 'SnowPunkz'">
        <v-container>
          <!-- Start Single Content  -->
          <v-row class="sercice-details-content align-center row--35">
            <v-col lg="12" md="12" cols="12" order="2">
              <div class="details mt_md--30 mt_sm--30">
                <div class="section-title">
                  <span class="subtitle">Implementing the solution</span>
                  <h2 class="heading-title">
                    {{ clientobj[0]["action_title"] }}
                  </h2>
                  <a class="btn-transparent">The Action</a>
                  <p class="description">
                    I created a website comprising few information pages and a NFT
                    Sale page, where user could mint an NFT by paying 40 ADA
                    (Cardano cryptocurrency coins). I Installed cardano node on a
                    Linux based server and used Laravel for the website with
                    Javascript Cardano Serialization Library for minting cardano
                    NFTs.
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- End Single Content  -->
        </v-container>
        <!-- Start Key Highlights Area  -->
        <div
          class="rn-service-details pt--120 bg_color--1"
          v-if="$route.params.client == 'snowpunkz'"
        >
          <v-container>
            <v-row>
              <v-col lg="12">
                <div class="service-details-inner">
                  <div class="inner">
                    <!-- Start Single Content  -->
                    <v-row
                      class="
                        sercice-details-content
                        align-center
                        row--35
                        justify-center
                      "
                    >
                      <v-col lg="10" md="10" cols="10">
                        <v-card class="elevation-10" outlined>
                          <v-list-item three-line>
                            <v-list-item-content>
                              <v-card-title>Fun Detail</v-card-title>
                              <v-card-subtitle>
                                I was a core developer at SnowPunkz Phase-I team, where we donated 15000 ADA (worth $18K at time referred) out of sales revenue to one earth foundation towards fighting global warming.
                              </v-card-subtitle>
                            </v-list-item-content>
                            <div
                              style="color: #c56a2b; transform: scale(1.3)"
                              v-html="iconSvg('coffee')"
                            ></div>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- End Key Highlights Area  -->
      </div>

      <div class="rn-service-details ptb--120 bg_color--5" v-if="clientobj[0].name == 'Pocketguide'">
        <v-container>
          <!-- Start Single Content  -->
          <v-row class="align-center mt--10 mr--10 mb--10 ml--10">
            <v-col lg="6" md="6" sm="8" offset-sm="2" offset-md="0" offset-lg="0">
              <v-img contain
                :lazy-src="require('../../assets/images/spinner.svg')"
                max-height="600"
                max-width="650"
                :src="clientobj[0]['featured_image_2']"
              ></v-img>
            </v-col>
            <v-col lg="6" md="6" sm="12" class="mt_md--30 mt_sm--30">
              <div class="section-title">
                <span class="subtitle">Implementing the solution</span>
              </div>
              <h2 class="heading-title">
                {{ clientobj[0]["action_title"] }}
              </h2>
              <a class="btn-transparent">The Action</a>
              <p class="description">
                I started with data structure on the application, architecting new database and building admin panel on top of it for efficient data management(improved internal operating efficiency by over 40%). I wired up the backend for HTML mockup provided by designer with Laravel and Livewire. Voucher generation was not a part of this application, rather it was something already developed and in use with the mobile applications. I integrated same API end point to distribute the voucher on successful payment.
              </p>
              <p class="description">
                I implemented seamless checkout process for buying voucher and Integrated mollie payment gateway for the online payments. I was also responsible for incorporating marketing campaign specific features into website, such as buying voucher through affiliate link.
              </p>
            </v-col>
          </v-row>
          <!-- End Single Content  -->
        </v-container>
      </div>

      <div
        class="rn-service-details ptb--120 bg_color--5" v-if="clientobj[0].name == 'MSGold'"
      >
        <v-container>
          <!-- Start Single Content  -->
          <v-row class="align-center mt--10 mr--10 mb--10 ml--10">
            <v-col lg="6" md="6" sm="8" offset-sm="2" offset-md="0" offset-lg="0">
              <v-img contain
                :lazy-src="require('../../assets/images/spinner.svg')"
                max-height="600"
                max-width="650"
                :src="clientobj[0]['featured_image_2']"
              ></v-img>
            </v-col>
            <v-col lg="6" md="6" sm="12" class="mt_md--30 mt_sm--30">
              <div class="section-title">
                <span class="subtitle">Implementing the solution</span>
              </div>
              <h2 class="heading-title">
                {{ clientobj[0]["action_title"] }}
              </h2>
              <a class="btn-transparent">The Action</a>
              <p class="description">
                I created a website with backend admin panel and seeded provided HTML mockup with dynamic data using Laravel. I also integrated google maps API for find your dealer page, where user could filter out dealers based on the location within said radius, It was a multi-language applications consisting of 6 different languages, I utilised Laravel localization for that.
              </p>
              <p class="description">
                Other Significant part of application was become a dealer request functionality, a page where user could signup to become a dealer, and admin could approve them in backend with an email notification on new signups. Once approved by admin, new dealers were added & made available in find your dealer listing as well.
              </p>
            </v-col>
          </v-row>
          <!-- End Single Content  -->
        </v-container>
      </div>

      <div class="rn-service-details ptb--120 bg_color--5" v-if="clientobj[0].name == 'Motorpark'">
        <v-container>
          <!-- Start Single Content  -->
          <v-row class="align-center mt--10 mr--10 mb--10 ml--10">
            <v-col lg="6" md="6" sm="8" offset-sm="2" offset-md="0" offset-lg="0">
                <v-img contain
                  :lazy-src="require('../../assets/images/spinner.svg')"
                  max-height="600"
                  max-width="650"
                  :src="clientobj[0]['featured_image_2']"
                ></v-img>
            </v-col>
            <v-col lg="6" md="6" sm="12" class="mt_md--30 mt_sm--30">
              <div class="section-title">
                <span class="subtitle">Implementing the solution</span>
              </div>
              <h2 class="heading-title">
                {{ clientobj[0]["action_title"] }}
              </h2>
              <a class="btn-transparent">The Action</a>
              <p class="description">
                I created an Ecommerce website application using TALL stack(Tailwind, Alpine.js, Laravel, and Livewire), I built functionality rich website specific to requirements. It included developing Real time chatting (I used Laravel Websockets) and product search page with advance search where user could filter out Motorcycles based on various characteristics.
              </p>
              <p class="description">
                I created subscription model Flow in Laravel Livewire where user could pay for displaying their Motorcycle advertisement on the website for the period based on active subscription,I also integrated Mollie payment gateway for the advertisement subscription payments.
              </p>
              <p class="description">
                There were more components to it such as News Letter, Following standard coding practice, I developed reusable news letter component using Livewire.
              </p>
            </v-col>
          </v-row>
          <!-- End Single Content  -->
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->

      <!-- Start Other Projects Area -->
      <div class="portfolio-area ptb--120 bg_color--1" id="otherprojects">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title mb--30 mb_sm--0">
                <a class="btn-transparent">Other Projects</a>
              </div>
            </v-col>
          </v-row>
          <PeekIntoPortfolio :client="this.$route.params.client" />
        </v-container>
      </div>
      <!-- End Other Projects Area -->

      <Footer />
    </div>
  </template>

  <script>
import Header from "../Header";
import Footer from "../Footer";
import PeekIntoPortfolio from "../PeekIntoPortfolio";
import Accordion from "../Accordion";
import Counter from "../Counter";
import feather from "feather-icons";

export default {
  props: ["client"],
  components: {
    Header,
    Footer,
    PeekIntoPortfolio,
    Accordion,
    Counter,
  },
  computed: {
    clientobj() {
      var clientLocal = this.$route.params.client;
      if (clientLocal == "mesa-coatings") {
        return this.mesa;
      } else if (clientLocal == "snowpunkz") {
        return this.snowpunkz;
      } else if (clientLocal == "pocketguide") {
        return this.pocketguide;
      } else if (clientLocal == "msgold") {
        return this.msgold;
      } else if (clientLocal == "motorpark") {
        return this.motorpark;
      } /*  else {
          return this.kroetenwind;
        } */
    },
    isXSSM() {
      return true;
      // return ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm);
    },
  },
  data() {
    return {
      logo: require("../../assets/images/skills/git-logo.svg"),
      mesa: [
        {
          name: "Mesa",
          linkExists: false,
          highlight:
            "Built an Intranet website, which improved business internal operating efficiency by over 60%, which translates to approx 15% incremental Business revenue.",
          about_client:
            "Mesa-coatings is an international company headquartered in the Netherlands, They supply around 1000 coating systems per year for a wide range of industries, from car washes to industrial buildings, which Includes employing around 40-60 field employees with few managerial & administrator roles. Mesa has benchmarked more than 5000 successful projects with many years of experience in the Industry.",
          problem:
            "At times Internal Business operations for Mesa coatings were carried out offline. Every day, an average of 50 field employees sets out to install Mesa Coatings products in different locations, whom were managed & scheduled by 5 to 7 administrator and managerial roles. They used mix of Gsuite applications (google calendar, google drive, google map) with papers for all the work, and It stood out to be complicated and error prone. So They decided to go digital for the said specifics.",
          featured_image: require("../../assets/images/portfolio-details/mesa/mesa.jpeg"),
          action_title: "Building The Mesa Intranets",
          tech_stack: "LAMP Stack",
          tech_stack_desc: "Linux, Apache, MySQL, and PHP (Laravel)",
        },
      ],
      pocketguide: [
        {
          name: "Pocketguide",
          linkExists: 'https://www.pocketguideaudiotours.com/en',
          highlight:
            "Built an ecommerce webapp to sell audio tour vouchers online, Increased company sales by 25%.",
          about_client:
            "Pocketguide is world’s leading audio tour platform, available in 150+ major cities worldwire, with 4.2 rating and 500k+ downloads on google play store. They provide audio city guide powered by their android and iOS mobile applications.",
          problem:
            "Pocketguide wanted an extension to their existing platform. A web app where users could see and buy voucher for city guides of their choice, which later on can be redeemed in mobile application for the use.",
          featured_image: require("../../assets/images/portfolio-details/pocketguide/pocketguide.png"),
          featured_image_2: require("../../assets/images/portfolio-details/pocketguide/pocketguide_2.png"),
          action_title: "Building an Ecommerce webapp",
          tech_stack: "LAMP Stack",
          tech_stack_desc: "Linux, Apache, MySQL, and PHP (Laravel)",
        },
      ],
      snowpunkz: [
        {
          name: "SnowPunkz",
          linkExists: false,
          highlight:
            "Developed a launch website for selling NFTs, sold 100% NFTs (8888) within 3 hours of launch with zero percent downtime, generating $250k sales revenue.",
          about_client:
            "SnowPunkz was a community driven NFT collection project on Cardano blockchain with unique 8888 NFTs.",
          problem:
            "SnowPunkz needed a website featuring their scheduled Phase-I Cardano NFT Sale.",
          featured_image: require("../../assets/images/portfolio-details/snowpunkz/snowpunkz.png"),
          action_title: "Building an NFT sales website",
          tech_stack: "TALL Stack",
          tech_stack_desc: "Tailwind, Alpine.js, Laravel, and Livewire",
        },
      ],
      msgold: [
        {
          name: "MSGold",
          linkExists: false,
          highlight:
            "Built product website for farm hygiene products company, Boosted company sales by 25% through find & contact nearest dealer functionality, representing a 5% quarter-on-quater increase, Increased organic signups rate for becoming a dealer by 10%, by developing become a dealer request functionality for the website.",
          about_client:
            "The Schippers Group is one of the largest international suppliers for intensive livestock farming globally with 14 offices, activities in 40+ countries and more than 500 employees. MSGold is one of their product range consisting of the very best hygiene and care products, created with livestock farmers, for livestock farmers.",
          problem:
            "MS Schippers needed a website for their MSGold product range, with few information pages and functionalities involving dealership of their product, where user could signup to become a dealer or user could find a nearest dealer for the product from huge list of international dealers available.",
          featured_image: require("../../assets/images/portfolio-details/msgold/msgold.png"),
          featured_image_2: require("../../assets/images/portfolio-details/msgold/msgold_2.png"),
          action_title: "Building The Product Website",
          tech_stack: "LAMP Stack",
          tech_stack_desc: "Linux, Apache, MySQL, and PHP (Laravel)",
        },
      ],
      motorpark: [
        {
          name: "Motorpark",
          linkExists: false,
          highlight:
            "Built Ecommerce website for Motorcycles, increased user advertisement organic signup rate by 5%.",
          about_client:
            "Motorpark was a small start-up based in Netherlands, with Team of 4 they built a company empowering idea of Ecommerce platform for Motorcycles.",
          problem:
            "Motorpark needed an Motorcycle Ecommerce website for their company.",
          featured_image: require("../../assets/images/portfolio-details/motorpark/motorpark.png"),
          featured_image_2: require("../../assets/images/portfolio-details/motorpark/motorpark_2.png"),
          action_title: "Building the Website",
          tech_stack: "TALL Stack",
          tech_stack_desc: "Tailwind, Alpine.js, Laravel, and Livewire",
        },
      ],
      kroetenwind: [
        {
          name: "Kroetenwind",
          linkExists: false,
          highlight: "Developed ",
          about_client:
            "Kroetenwind is an Environment and Living Climate Foundation based in Netherlands. During the development of the Kroeten, located in the municipality of Breda Netherlands, it was decided to establish the Environment and Living Climate Foundation Kroetenwind. T",
          problem:
            "MS Schippers needed a website for their MSGold product range, with few information pages and functionalities involving dealership of their product, where user could signup to become a dealer or user could find a nearest dealer for the product from huge list of international dealers available.",
          featured_image: require("../../assets/images/portfolio-screens/kroeten.jpg"),
          action_title: "Building Kroetenwind application",
        },
      ],
      index: null,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Other Portfolio Projects",
          to: "#otherprojects",
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
