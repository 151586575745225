<template>
    <div id="parent">
      <!-- Start Header Area -->
      <Header />
      <!-- End Header Area -->
      <!-- Start Home Area  -->
      <div class="slider-wrapper poss_relative pb--120" id="home">
        <div
          class="
            justify-center
            slide
            personal-portfolio-slider
            slider-paralax slider-style-3
            d-flex
            align-center
            bg_image
          "
          :class="[{ 'bg_image--25' : ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : true)}]"
        >
          <v-container>
            <v-row>
              <v-col lg="12">
                <div class="text-left inner">
                  <span class="theme-color font-700">Full Stack Developer</span>
                  <h1 class="heading-title theme-gradient">
                    Greetings, I’m <span>Denish</span>.<br />Full Stack Developer
                    with 4+ years</br>of hands-on experience in the </br>IT industry.
                  </h1>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <!-- End Home Area -->

      <!-- Start Skills Area  -->
      <div class="about-area ptb--120 bg_color--5 text-center" id="skills">
        <a class="btn-transparent" style="font-size: 1.2em;">Tech-Skills</a>
        <div class="about-wrapper">
          <v-container>
            <Skill />
          </v-container>
        </div>
      </div>
      <!-- Start Skills Area  -->

      <!-- Start Portfolio Area -->
      <div class="portfolio-area ptb--120" id="portfolio">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title mb--30 mb_sm--0">
                <span class="subtitle">Portfolio showcase</span>
                <h2 class="heading-title">My Recent Projects</h2>
                <p>
                  Over 4+ years of my career, I worked with many different web applications using cutting-edge technologies.
                </p>
              </div>
            </v-col>
          </v-row>
          <PeekIntoPortfolio :client="false" />
        </v-container>
      </div>
      <!-- End Portfolio Area -->

      <!-- Start Work Experience Area  -->
      <div class="about-area ptb--120" id="experience">
        <!-- <h4 class="heading-title text-center text-underline">Work experience</h4> -->
        <div class="about-wrapper">
          <v-container>
            <v-row>
            <v-col lg="12" class="text-center ">
              <a class="btn-transparent">Work Experience</a>
            </v-col>
          </v-row>
            <About></About>
          </v-container>
        </div>
      </div>
      <!-- Start Work Experience Area  -->

      <!-- Start Contact Area  -->
      <div class="rn-contact-area ptb--120" id="contact" v-if="false">
        <div class="contact-form--1">
          <v-container>
            <Contact v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : true)">
              <img
                slot="contact-img"
                class="w-100"
                src="../assets/images/cases/businessman.svg"
                alt="contact images"
              />
            </Contact>
          </v-container>
        </div>
      </div>
      <!-- End Contact Area  -->

      <!-- Start Footer Area  -->
      <Footer />
      <!-- End Footer Area  -->
    </div>
  </template>

  <script>
  import Header from "../Header";
  import About from "../About";
  import Service from "../Service";
  import Skill from "../Skill";
  import PeekIntoPortfolio from "../PeekIntoPortfolio";
  // import Contact from "../Contact";
  import Footer from "../Footer";

  export default {
    components: {
      Header,
      About,
      Service,
      Skill,
      PeekIntoPortfolio,
      // Contact,
      Footer,
    },
    data() {
      return {};
    },
  };
  </script>

  <style lang="scss">
  .feather-menu {
    color: #1d1d24;
  }
  .skill-type {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    background: linear-gradient(
      120deg,
      #1c99fe 20.69%,
      #7644ff 50.19%,
      #fd4766 79.69%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    padding-bottom: 1px;
  }
  .skill-logo {
    width: 30px;
    height: 19px;
    vertical-align: middle;
    padding-left: 5px;
  }
  .skill-logo.php {
    width: 42px;
    height: 20px;
  }
  .skill-logo.mysql {
    width: 44px;
    height: 20px;
  }
  .skills-card-title {
    background: -webkit-linear-gradient(
      120deg,
      #1c99fe 20.69%,
      #7644ff 50.19%,
      #fd4766 79.69%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1px solid #fe4766;
    border-radius: 0% !important;
    font-weight: 600;
    font-size: 20px;
  }
  .skills-card {
    padding: 10px;
    font-size: large;
    color: black;
    font-weight: 450;
  }
  .skillCardHtmlLogo {
    height: 25px;
  }
  .skillCardHtmlLogo {
    width: 24px;
  }
  .skillCardLivewireLogo {
    height: 28px;
  }
  .skillCardMysqlLogo {
    width: 42px;
  }
  .skillCardPythonLogo {
    height: 28px;
  }
  .skillCardGitLogo {
    height: 25px;
  }
  .skillCardChromeLogo {
    height: 29px;
  }
  .skillCardGitLogo,
  .skillCardPostmanLogo {
    height: 24px;
  }
  .text-underline {
    text-decoration: underline;
  }
  .slider-progress {
    background: linear-gradient(
      120deg,
      #1c99fe 20.69%,
      #7644ff 50.19%,
      #fd4766 79.69%
    ) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .socialLinkedin {
    color: #1c99fe;
  }
  .socialMail {
    color: #7644ff;
  }
  .socialGit {
    color: #1d1d24;
  }
  #portfolio-carousel .v-image__image {
    background-size: auto !important;
  }
  #portfolio-carousel .v-btn .v-btn__content .v-icon {
    color: white;
  }
  #portfolio-carousel {
    .v-btn {
      color: transparent !important;
      border: 1px solid #fe4766 !important;
      background-color: transparent !important;
    }

    .v-btn--active {
      color: #fe4766 !important;
      border: 1px solid #fe4766 !important;
      background-color: #fe4766 !important;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: #ff4f5a;
    opacity: 1;
  }

  .v-timeline-item__body > .v-card:not(.v-card--flat)::after {
      border-right-color: #e0e0e0 !important;
  }
  .category_list a {
    font-size: 15px !important;
  }
  .social-share-inner {
    margin-top: 8px;
  }
  #skills {
    img {
      width: 50px !important;
      height: 50px !important;
    }
    .skill_anchor {
      &:hover {
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -o-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transition: transform 0.28s ease;
          cursor: pointer;
      }
      &:active {
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -o-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transition: transform 0.28s ease;
          cursor: pointer;
      }
    }
  }
  </style>
